import React, { useState, useEffect } from 'react';

const RealEstateMap = () => {
  const [map, setMap] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Sample properties array
  const properties = [
    {
      listing_type: "sale",
      price: 527900,
      bedrooms: 4,
      bathrooms: 3,
      size_sqft: 2246,
      address: {
        street: "7292 Nautica Way",
        city: "Lake Worth",
        state: "FL",
        zip_code: "33467",
        country: "USA",
        latitude: 26.62806484321901,
        longitude: -80.12410721025458
      },
      pictures: [
        "static/Images/1/1.jpg",
        "static/Images/1/2.jpg",
        "static/Images/1/3.jpg",
        "static/Images/1/4.jpg"
      ]
    },
    {
      listing_type: "rent",
      price: 2500,
      bedrooms: 2,
      bathrooms: 2,
      size_sqft: 1200,
      address: {
        street: "123 Palm Ave",
        city: "Lake Worth",
        state: "FL",
        zip_code: "33467",
        country: "USA",
        latitude: 26.6290,
        longitude: -80.1240
      },
      pictures: [
        "static/Images/2/1.jpg",
        "static/Images/2/2.jpg"
      ]
    }
  ];

  // Marker colors based on listing type
  const markerColors = {
    sale: '#2563eb',  // Blue
    rent: '#16a34a'   // Green
  };

  // Create SVG marker
  const createCircleMarker = (color) => {
    const svg = `
      <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="12" fill="${color}" stroke="white" stroke-width="2" />
        <circle cx="20" cy="20" r="16" fill="${color}" fill-opacity="0.3" />
      </svg>
    `;
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  };

  const formatPrice = (price) => {
    return price.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    });
  };

  // Image navigation functions
  const nextImage = (e) => {
    e.stopPropagation();
    if (selectedProperty) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === selectedProperty.pictures.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const previousImage = (e) => {
    e.stopPropagation();
    if (selectedProperty) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === 0 ? selectedProperty.pictures.length - 1 : prevIndex - 1
      );
    }
  };

  useEffect(() => {
    setCurrentImageIndex(0);
  }, [selectedProperty]);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDkIKfrBfuZy-DkZGY8nZFqnDDDxNIVKoc`;
      script.async = true;
      script.defer = true;
      script.onload = initializeMap;
      document.body.appendChild(script);
    };

    const initializeMap = () => {
      // Center map on first property
      const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
        center: { 
          lat: properties[0].address.latitude, 
          lng: properties[0].address.longitude 
        },
        zoom: 14,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }]
          }
        ]
      });

      setMap(mapInstance);

      // Add markers for all properties
      properties.forEach(property => {
        const marker = new window.google.maps.Marker({
          position: { 
            lat: property.address.latitude, 
            lng: property.address.longitude 
          },
          map: mapInstance,
          icon: {
            url: createCircleMarker(markerColors[property.listing_type]),
            scaledSize: new window.google.maps.Size(40, 40),
            anchor: new window.google.maps.Point(20, 20)
          },
          title: property.address.street
        });

        // Create tooltip
        const tooltip = new window.google.maps.InfoWindow({
          content: `
            <div style="padding: 8px; font-family: Arial, sans-serif;">
              <div style="font-weight: bold; margin-bottom: 4px;">${formatPrice(property.price)}</div>
              <div style="font-size: 12px; color: #666;">
                ${property.bedrooms} beds • ${property.bathrooms} baths
              </div>
            </div>
          `,
          pixelOffset: new window.google.maps.Size(0, -20)
        });

        // Add hover effect
        marker.addListener('mouseover', () => {
          tooltip.open(mapInstance, marker);
        });

        marker.addListener('mouseout', () => {
          tooltip.close();
        });

        // Add click handler
        marker.addListener('click', () => {
          tooltip.close();
          setSelectedProperty(property);
          setIsPopupVisible(true);
        });
      });
    };

    loadGoogleMapsScript();
  }, []);

  const handlePopupClose = () => {
    setIsPopupVisible(false);
    setTimeout(() => setSelectedProperty(null), 300);
  };

  // Navigation button component
  const ImageNavButton = ({ direction, onClick }) => (
    <button
      onClick={onClick}
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        [direction === 'prev' ? 'left' : 'right']: '15px',
        background: 'rgba(255, 255, 255, 0.9)',
        border: 'none',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '24px',
        color: '#333',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 2
      }}
    >
      {direction === 'prev' ? '‹' : '›'}
    </button>
  );

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <div id="map" style={{ width: '100%', height: '100%' }} />
      
      {/* Legend */}
      <div style={{
        position: 'absolute',
        bottom: '24px',
        left: '24px',
        backgroundColor: 'white',
        padding: '12px',
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
        zIndex: 1
      }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '8px' }}>
          Property Types
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: markerColors.sale
            }} />
            <span style={{ fontSize: '12px' }}>For Sale</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: markerColors.rent
            }} />
            <span style={{ fontSize: '12px' }}>For Rent</span>
          </div>
        </div>
      </div>

      {/* Property Popup */}
      {selectedProperty && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            opacity: isPopupVisible ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
            backdropFilter: 'blur(5px)'
          }}
          onClick={handlePopupClose}
        >
          <div 
            style={{
              backgroundColor: 'white',
              borderRadius: '16px',
              maxWidth: '600px',
              width: '90%',
              position: 'relative',
              overflow: 'hidden',
              boxShadow: '0 20px 40px rgba(0, 0, 0, 0.2)',
              transform: isPopupVisible ? 'scale(1)' : 'scale(0.9)',
              transition: 'transform 0.3s ease'
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Image Slider */}
            <div style={{ position: 'relative', height: '300px' }}>
              <img
                src={selectedProperty.pictures[currentImageIndex]}
                alt={`Property ${currentImageIndex + 1}`}
                style={{
                  width: '100%',
                  height: '300px',
                  objectFit: 'cover'
                }}
                onError={(e) => {
                  e.target.src = '/api/placeholder/600/300';
                }}
              />
              
              {selectedProperty.pictures.length > 1 && (
                <>
                  <ImageNavButton direction="prev" onClick={previousImage} />
                  <ImageNavButton direction="next" onClick={nextImage} />
                  
                  {/* Image Counter */}
                  <div style={{
                    position: 'absolute',
                    bottom: '15px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    display: 'flex',
                    gap: '8px'
                  }}>
                    {selectedProperty.pictures.map((_, index) => (
                      <div
                        key={index}
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          backgroundColor: index === currentImageIndex ? 'white' : 'rgba(255, 255, 255, 0.5)'
                        }}
                      />
                    ))}
                  </div>
                </>
              )}

              {/* Property Type Badge */}
              <div style={{
                position: 'absolute',
                top: '20px',
                left: '20px',
                backgroundColor: markerColors[selectedProperty.listing_type],
                color: 'white',
                padding: '6px 12px',
                borderRadius: '20px',
                fontSize: '14px',
                fontWeight: '500',
                textTransform: 'capitalize'
              }}>
                For {selectedProperty.listing_type}
              </div>

              {/* Close Button */}
              <button
                onClick={handlePopupClose}
                style={{
                  position: 'absolute',
                  top: '20px',
                  right: '20px',
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  border: 'none',
                  width: '36px',
                  height: '36px',
                  borderRadius: '50%',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '20px'
                }}
              >
                ×
              </button>
            </div>

            {/* Property Details */}
            <div style={{ padding: '24px' }}>
              <h2 style={{ 
                margin: '0 0 12px 0',
                color: '#333',
                fontSize: '28px',
                fontWeight: '600'
              }}>
                {formatPrice(selectedProperty.price)}
              </h2>
              
              <p style={{
                margin: '0 0 20px 0',
                color: '#666',
                fontSize: '16px'
              }}>
                {selectedProperty.address.street}<br />
                {selectedProperty.address.city}, {selectedProperty.address.state} {selectedProperty.address.zip_code}
              </p>

              <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '16px',
                marginBottom: '20px'
              }}>
                <div style={{
                  padding: '12px',
                  backgroundColor: '#f8f9fa',
                  borderRadius: '12px',
                  textAlign: 'center'
                }}>
                  <div style={{ fontSize: '20px', fontWeight: '600', color: '#333' }}>
                    {selectedProperty.bedrooms}
                  </div>
                  <div style={{ fontSize: '14px', color: '#666' }}>Beds</div>
                </div>
                <div style={{
                  padding: '12px',
                  backgroundColor: '#f8f9fa',
                  borderRadius: '12px',
                  textAlign: 'center'
                }}>
                  <div style={{ fontSize: '20px', fontWeight: '600', color: '#333' }}>
                    {selectedProperty.bathrooms}
                  </div>
                  <div style={{ fontSize: '14px', color: '#666' }}>Baths</div>
                </div>
                <div style={{
                  padding: '12px',
                  backgroundColor: '#f8f9fa',
                  borderRadius: '12px',
                  textAlign: 'center'
                }}>
                  <div style={{ fontSize: '20px', fontWeight: '600', color: '#333' }}>
                    {selectedProperty.size_sqft.toLocaleString()}
                  </div>
                  <div style={{ fontSize: '14px', color: '#666' }}>Sq Ft</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RealEstateMap;